/* Styles for MarkdownPreview component */
.blog-post {
    max-width: 980px; /* Maximum width of 980 pixels */
    width: 100%; /* Default to full width */
    background-color: white; /* White background */
    margin: 0 auto; /* Center the component */
}


.created-at {
    font-size: 0.8REM;
    max-width: 980px; /* Maximum width of 980 pixels */
    width: 100%; /* Default to full width */
    background-color: white; /* White background */
    margin: 0 auto; /* Center the component */
    color: rgb(0, 68, 25); /* Text color */
    text-align: center; /* Center text */
    font-family: utopia-std-subhead,Times,Times New Roman,serif;
    margin-bottom: 20px;
}


/* Media query for screen sizes less than 980 pixels */
@media screen and (max-width: 980px) {
    .blog-post {
        width: 90%; /* 3/4 of the screen width */
    }
}

.blog-title{
    background-color: rgb(0, 68, 25); /* Background color */
    color: #f1c40f; /* Golden text color */
    text-align: center; /* Center text */
    font-size: 3rem;
    margin-bottom: 1rem;
    /* Additional styles like padding, margin, etc., can be added here */
}

.markdown-content h1{
    font-weight: 800;
    font-size: 2rem;
}

.markdown-content {
    max-width: 980px; /* Maximum width of 980 pixels */
    width: 100%; /* Default to full width */
    background-color: white; /* White background */
    margin: 0 auto; /* Center the component */
    color: rgb(0, 68, 25); /* Text color */
    /*text-align: center; !* Center text *!*/
    font-family: utopia-std-subhead,Times,Times New Roman,serif;
    font-size: 1.2rem;
    font-weight: 400;

    padding-bottom: 3rem;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 1rem;
}

/* Media query for screen sizes less than 980 pixels */
@media screen and (max-width: 980px) {
    .markdown-content {
        width: 90%; /* 3/4 of the screen width */
    }
}

.new-post-form {
    font-family: utopia-std-subhead,Times,Times New Roman,serif;
    font-size: 1.2rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 90vh;
    background-color:  lightgray;


}


.button-holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-left: 400px;
    padding-right: 400px;
    margin-top: 50px;
    margin-bottom: 50px;
}