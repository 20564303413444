
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;900&display=swap');
.site-layout{
    font-family: "Raleway", sans-serif;
    font-size: 20px;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    font-weight: 300;
    color: White;

    perspective: 2px;
    height: 100vh;
    overflow-y: auto;
}

.amplify-button.amplify-field-group__control{
    color: white;
    background-color: #00BB45;
}
