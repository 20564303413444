.qcard{

    text-align: center; /* Center text */
    font-size: 3rem;
    margin-bottom: 1rem;
    /* Additional styles like padding, margin, etc., can be added here */
}

.ROTQuizPage{
    /*justify-content: center;*/
    /*align-content: center;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    text-align: center;

}

.ant-card .ant-card-head{
    font-size: 2rem;
    color: #004419;
    align-content: center;
}
